import React from "react";
import {FormattedMessage} from "react-intl";
import {Badge} from "reactstrap5";

export const USER_ROLE = {
    TEACHER: 'TEACHER',
    PARENT: 'PARENT',
    STUDENT_YOUNG: 'STUDENT_YOUNG',
    STUDENT_OLD: 'STUDENT_OLD',
    STUDENT_CODE: 'STUDENT_CODE',
};

export const USER_ROLE_MESSAGES = {
    TEACHER: <FormattedMessage defaultMessage={"Učitel"} />,
    PARENT: <FormattedMessage defaultMessage={"Rodič"} />,
    STUDENT_YOUNG: <FormattedMessage defaultMessage={"Mladší žák"} />,
    STUDENT_OLD: <FormattedMessage defaultMessage={"Starší žák"} />,
};

export const USER_ROLE_DELETE_MESSAGES = {
    TEACHER: <FormattedMessage defaultMessage={"Kliknutím na \"Smazat účet\" bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Máte-li ve svém profilu vytvořeny třídy, budou rovněž archivovány, a to bez možnosti jejich obnovy. Tento krok je nevratný!"} />,
    PARENT: <FormattedMessage defaultMessage={"Kliknutím na \"Smazat účet\" bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Máte-li ke svému profilu přiřazeny i účty svých dětí, budou rovněž smazány. Tento krok je nevratný!"} />,
    STUDENT_YOUNG: <FormattedMessage defaultMessage={"Kliknutím na \"Smazat účet\" bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Tento krok je nevratný!"} />,
    STUDENT_OLD: <FormattedMessage defaultMessage={"Kliknutím na \"Smazat účet\" bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Tento krok je nevratný!"} />,
};

export const USER_ROLE_DELETE_MESSAGES_CONFIRM = {
    TEACHER: <FormattedMessage defaultMessage={"Tímto bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Máte-li ve svém profilu vytvořeny třídy, budou rovněž archivovány, a to bez možnosti jejich obnovy. Tento krok je nevratný!"} />,
    PARENT: <FormattedMessage defaultMessage={"Tímto bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Máte-li ke svému profilu přiřazeny i účty svých dětí, budou rovněž smazány. Tento krok je nevratný!"} />,
    STUDENT_YOUNG: <FormattedMessage defaultMessage={"Tímto bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Tento krok je nevratný!"} />,
    STUDENT_OLD: <FormattedMessage defaultMessage={"Tímto bude odstraněn celý váš profil nejen na portále Škola s nadhledem, ale i na všech webových portálech FRAUS (fraus.cz, ucebnice.fraus.cz), a to včetně veškerých uložených dat a zakoupeného obsahu či licencí. Tento krok je nevratný!"} />,
};

export const ASSIGNMENT_STATE = {
    ASSIGNED: 'ASSIGNED',
    TO_CHECK: "TO_CHECK",
    CLOSED: 'CLOSED',
};

export const ASSIGNMENT_STATE_CHOICES = [
    {id: ASSIGNMENT_STATE.ASSIGNED, name: (<FormattedMessage defaultMessage={"Zadaný"} />)},
    {id: ASSIGNMENT_STATE.TO_CHECK, name: (<FormattedMessage defaultMessage={"Ke kontrole"} />)},
    {id: ASSIGNMENT_STATE.CLOSED, name: (<FormattedMessage defaultMessage={"Uzavřený"} />)},
];

export const MEMBER_ASSIGNMENT_STATE = {
    ASSIGNED: 'ASSIGNED',
    SUBMITTED: 'SUBMITTED',
    CLOSED: 'CLOSED',
};

export const MEMBER_ASSIGNMENT_STATE_CHOICES = [
    {id: MEMBER_ASSIGNMENT_STATE.ASSIGNED, name: (<FormattedMessage defaultMessage={"Zadaný"} />)},
    {id: MEMBER_ASSIGNMENT_STATE.SUBMITTED, name: (<FormattedMessage defaultMessage={"Odevzdaný"} />)},
    {id: MEMBER_ASSIGNMENT_STATE.CLOSED, name: (<FormattedMessage defaultMessage={"Uzavřený"} />)},
];

export const MESSAGE_AUTHOR = {
    TEACHER: 'TEACHER',
    STUDENT: 'STUDENT',
};

export const getAuthorFromRole = role => {

    if (Array.isArray(role)) {
        for(let i = 0, len = role.length; i < len;i++) {
            if (role[i] === USER_ROLE.TEACHER) {
                return MESSAGE_AUTHOR.TEACHER;
            }
        }
    } else if (role === USER_ROLE.TEACHER) {
        return MESSAGE_AUTHOR.TEACHER;
    }

    return MESSAGE_AUTHOR.STUDENT;
}

export const CLASSROOM_GRADE = {
    1: 'GRADE_1',
    2: 'GRADE_2',
    3: 'GRADE_3',
    4: 'GRADE_4',
    5: 'GRADE_5',
    6: 'GRADE_6',
    7: 'GRADE_7',
    8: 'GRADE_8',
    9: 'GRADE_9',
    KVARTA: 'GRADE_KVARTA',
    KVINTA: 'GRADE_KVINTA',
    OKTAVA: 'GRADE_OKTAVA',
    PRIMA: 'GRADE_PRIMA',
    SEKUNDA: 'GRADE_SEKUNDA',
    SEPTIMA: 'GRADE_SEPTIMA',
    SEXTA: 'GRADE_SEXTA',
    TERCIE: 'GRADE_TERCIE',
}

export const CLASSROOM_GRADE_CHOICES = [
    { id: CLASSROOM_GRADE[1], name: (<FormattedMessage defaultMessage={"1."} />) },
    { id: CLASSROOM_GRADE[2], name: (<FormattedMessage defaultMessage={"2."} />) },
    { id: CLASSROOM_GRADE[3], name: (<FormattedMessage defaultMessage={"3."} />) },
    { id: CLASSROOM_GRADE[4], name: (<FormattedMessage defaultMessage={"4."} />) },
    { id: CLASSROOM_GRADE[5], name: (<FormattedMessage defaultMessage={"5."} />) },
    { id: CLASSROOM_GRADE[6], name: (<FormattedMessage defaultMessage={"6."} />) },
    { id: CLASSROOM_GRADE[7], name: (<FormattedMessage defaultMessage={"7."} />) },
    { id: CLASSROOM_GRADE[8], name: (<FormattedMessage defaultMessage={"8."} />) },
    { id: CLASSROOM_GRADE[9], name: (<FormattedMessage defaultMessage={"9."} />) },
    { id: CLASSROOM_GRADE.PRIMA, name: (<FormattedMessage defaultMessage={"Prima"} />) },
    { id: CLASSROOM_GRADE.SEKUNDA, name: (<FormattedMessage defaultMessage={"Sekunda"} />) },
    { id: CLASSROOM_GRADE.TERCIE, name: (<FormattedMessage defaultMessage={"Tercie"} />) },
    { id: CLASSROOM_GRADE.KVARTA, name: (<FormattedMessage defaultMessage={"Kvarta"} />) },
    { id: CLASSROOM_GRADE.KVINTA, name: (<FormattedMessage defaultMessage={"Kvinta"} />) },
    { id: CLASSROOM_GRADE.SEXTA, name: (<FormattedMessage defaultMessage={"Sexta"} />) },
    { id: CLASSROOM_GRADE.SEPTIMA, name: (<FormattedMessage defaultMessage={"Septima"} />) },
    { id: CLASSROOM_GRADE.OKTAVA, name: (<FormattedMessage defaultMessage={"Oktáva"} />) },
];

export const ACTIVITY_GAME = {
    ANONE: 'ANONE',
    AUDIO: 'AUDIO',
    CLOZE: 'CLOZE',
    FLASHCARDS: 'FLASHCARDS',
    KOMBINOVANA: 'KOMBINOVANA',
    KRIZOVKA: 'KRIZOVKA',
    KVIZ: 'KVIZ',
    MODEL3D: 'MODEL3D',
    ODKAZ: 'ODKAZ',
    PDF: 'PDF',
    PEXESO: 'PEXESO',
    PICTURE: 'PICTURE',
    PRETAHOVANI: 'PRETAHOVANI',
    TRIDENI: 'TRIDENI',
    VIDEO: 'VIDEO',
    TIMELINE: 'TIMELINE',
    CHATBOT: 'CHATBOT',
    DIKTAT: 'DIKTAT',
    OZNACOVANITEXTU: 'OZNACOVANITEXTU',
    ALGORITMIZACE: 'ALGORITMIZACE',
    TANGRAM: 'TANGRAM',
    OZNACOVANIOBRAZKU: 'OZNACOVANIOBRAZKU',
    SIFROVANI: 'SIFROVANI',
    MYSLENKOVAMAPA: 'MYSLENKOVAMAPA',
}

export const ACTIVITY_GAMES = [
    { id: ACTIVITY_GAME.ANONE, name: (<FormattedMessage defaultMessage={"Ano - ne"} />) },
    { id: ACTIVITY_GAME.AUDIO, name: (<FormattedMessage defaultMessage={"Zvuk"} />) },
    { id: ACTIVITY_GAME.CLOZE, name: (<FormattedMessage defaultMessage={"Doplňování do textu"} />) },
    { id: ACTIVITY_GAME.FLASHCARDS, name: (<FormattedMessage defaultMessage={"Flashcards"} />) },
    { id: ACTIVITY_GAME.KOMBINOVANA, name: (<FormattedMessage defaultMessage={"Kombinované"} />) },
    { id: ACTIVITY_GAME.KRIZOVKA, name: (<FormattedMessage defaultMessage={"Křížovka"} />) },
    { id: ACTIVITY_GAME.KVIZ, name: (<FormattedMessage defaultMessage={"Kvíz"} />) },
    { id: ACTIVITY_GAME.MODEL3D, name: (<FormattedMessage defaultMessage={"3D Model"} />) },
    { id: ACTIVITY_GAME.ODKAZ, name: (<FormattedMessage defaultMessage={"Odkaz"} />) },
    { id: ACTIVITY_GAME.PDF, name: (<FormattedMessage defaultMessage={"PDF"} />) },
    { id: ACTIVITY_GAME.PEXESO, name: (<FormattedMessage defaultMessage={"Pexeso"} />) },
    { id: ACTIVITY_GAME.PICTURE, name: (<FormattedMessage defaultMessage={"Obrázek"} />) },
    { id: ACTIVITY_GAME.PRETAHOVANI, name: (<FormattedMessage defaultMessage={"Přetahování"} />) },
    { id: ACTIVITY_GAME.TRIDENI, name: (<FormattedMessage defaultMessage={"Třídění"} />) },
    { id: ACTIVITY_GAME.VIDEO, name: (<FormattedMessage defaultMessage={"Video"} />) },
    { id: ACTIVITY_GAME.TIMELINE, name: (<FormattedMessage defaultMessage={"Časová osa"} />) },
    { id: ACTIVITY_GAME.CHATBOT, name: (<FormattedMessage defaultMessage={"Chatbot"} />) },
    { id: ACTIVITY_GAME.DIKTAT, name: (<FormattedMessage defaultMessage={"Diktát"} />) },
    { id: ACTIVITY_GAME.OZNACOVANITEXTU, name: (<FormattedMessage defaultMessage={"Označování textu"} />) },
    { id: ACTIVITY_GAME.ALGORITMIZACE, name: (<FormattedMessage defaultMessage={"Algoritmizace"} />) },
    { id: ACTIVITY_GAME.TANGRAM, name: (<FormattedMessage defaultMessage={"Parketování"} />) },
    { id: ACTIVITY_GAME.OZNACOVANIOBRAZKU, name: (<FormattedMessage defaultMessage={"Označování obrázku"} />) },
    { id: ACTIVITY_GAME.SIFROVANI, name: (<FormattedMessage defaultMessage={"Šifrování"} />) },
    { id: ACTIVITY_GAME.MYSLENKOVAMAPA, name: (<FormattedMessage defaultMessage={"Myšlenková mapa"} />) },
];

export const DIFFICULTY_LEVELS = {
    A: "Lehké",
    B: "Střední",
    C: "Těžké"
}

export const DIFFICULTY_LEVELS_CLASS = {
    A: "icon-level-1",
    B: "icon-level-2",
    C: "icon-level-3"
}

export const NEWS_TARGET = {
    TEACHER: 'TEACHER',
    PARENT: 'PARENT',
}

export function getNewsTargetFromRole(role) {
    if (Array.isArray(role)) {
        for(let i = 0, len = role.length; i < len;i++) {
            if (role[i] === USER_ROLE.TEACHER) {
                return NEWS_TARGET.TEACHER;
            }
        }
    } else if (role === USER_ROLE.TEACHER) {
        return NEWS_TARGET.TEACHER;
    }

    return NEWS_TARGET.PARENT;
}

export const STAGE_CLASS_LABEL_ID = {
    FIRST: 528,
    SECOND: 9
}

export const PRODUCT_TYPE_FAMILY = 'FAMILY';
export const PRODUCT_TYPE_TEACHER = 'TEACHER';

export const PRODUCT_TYPES = [
    {id: PRODUCT_TYPE_FAMILY, name: (<Badge color="success" className="p-1 text-14"><FormattedMessage defaultMessage={"Rodičovská"} /></Badge>)},
    {id: PRODUCT_TYPE_TEACHER, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Učitelská"} /></Badge>)},
];

export const PRODUCT_CATEGORY_PREMIUM = 'PREMIUM';
export const PRODUCT_CATEGORY_PACKAGE = 'PACKAGE';

export const PRODUCT_CATEGORIES = [
    {id: PRODUCT_CATEGORY_PREMIUM, name: (<Badge color="blue" className="p-1 text-14"><FormattedMessage defaultMessage={"Premium"} /></Badge>)},
    {id: PRODUCT_CATEGORY_PACKAGE, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Obsahový balíček"} /></Badge>)},
];

export const ORDER_STATUS_NEW = 'NEW';
export const ORDER_STATUS_WAIT = 'WAIT';
export const ORDER_STATUS_COMPLETED = 'COMPLETED';
export const ORDER_STATUS_CANCELED = 'CANCELED';
export const ORDER_STATUS_AUTO_CANCELED = 'AUTO_CANCELED';


export const ORDER_STATUSES = [
    {id: ORDER_STATUS_NEW, name: (<Badge color="black" className="p-1 text-14"><FormattedMessage defaultMessage={"Nová"} /></Badge>)},
    {id: ORDER_STATUS_WAIT, name: (<Badge color="black" className="p-1 text-14"><FormattedMessage defaultMessage={"Přijatá"} /></Badge>)},
    {id: ORDER_STATUS_COMPLETED, name: (<Badge color="success" className="p-1 text-14"><FormattedMessage defaultMessage={"Vyřízená"} /></Badge>)},
    {id: ORDER_STATUS_CANCELED, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Zrušená"} /></Badge>)},
    {id: ORDER_STATUS_AUTO_CANCELED, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Automatické storno"} /></Badge>)},
];

export const ORDER_STATUSES_TEXT = [
    {id: ORDER_STATUS_NEW, name: (<FormattedMessage defaultMessage={"Nová"} />)},
    {id: ORDER_STATUS_WAIT, name: (<FormattedMessage defaultMessage={"Přijatá"} />)},
    {id: ORDER_STATUS_COMPLETED, name: (<FormattedMessage defaultMessage={"Vyřízená"} />)},
    {id: ORDER_STATUS_CANCELED, name: (<FormattedMessage defaultMessage={"Zrušená"} />)},
    {id: ORDER_STATUS_AUTO_CANCELED, name: (<FormattedMessage defaultMessage={"Automatické storno"} />)},
];

export const PAYMENT_TYPE_INVOICE = 'INVOICE';

export const PAYMENT_TYPES = [
    {id: 'CARD', name: (<FormattedMessage defaultMessage={"Kartou"} />)},
    {id: 'APPLE_PAY', name: (<FormattedMessage defaultMessage={"Apple Pay"} />)},
    {id: 'GOOGLE_PAY', name: (<FormattedMessage defaultMessage={"Google Pay"} />)},
    {id: PAYMENT_TYPE_INVOICE, name: (<FormattedMessage defaultMessage={"Fakturou"} />)},
    {id: 'GRATIS', name: (<FormattedMessage defaultMessage={"Zdarma"} />)},
    {id: 'TRIAL', name: (<FormattedMessage defaultMessage={"Zdarma"} />)},
];

export const PAYMENT_STATUS_NEW = 'NEW';
export const PAYMENT_STATUS_WAIT = 'WAIT';
export const PAYMENT_STATUS_COMPLETED = 'COMPLETED';
export const PAYMENT_STATUS_CANCELED = 'CANCELED';

export const PAYMENT_STATUSES = [
    {id: PAYMENT_STATUS_NEW, name: (<FormattedMessage defaultMessage={"Nová"} />)},
    {id: PAYMENT_STATUS_WAIT, name: (<FormattedMessage defaultMessage={"Čeká se na platbu"} />)},
    {id: PAYMENT_STATUS_COMPLETED, name: (<FormattedMessage defaultMessage={"Dokončená"} />)},
    {id: PAYMENT_STATUS_CANCELED, name: (<FormattedMessage defaultMessage={"Zrušená"} />)},
];

export const LICENCE_STATUS_ACTIVE = 'ACTIVE';
export const LICENCE_STATUS_CANCEL = 'CANCEL';
export const LICENCE_STATUS_EXPIRED = 'EXPIRED';
export const LICENCE_STATUS_WAIT = 'WAIT';

export const LICENCE_STATUSES_TEXT = [
    {id: LICENCE_STATUS_ACTIVE, name: (<FormattedMessage defaultMessage={"Aktivní"} />)},
    {id: LICENCE_STATUS_CANCEL, name: (<FormattedMessage defaultMessage={"Zrušená"} />)},
    {id: LICENCE_STATUS_EXPIRED, name: (<FormattedMessage defaultMessage={"Expirovaná"} />)},
    {id: LICENCE_STATUS_WAIT, name: (<FormattedMessage defaultMessage={"Čeká"} />)},
];

export const LICENCE_STATUSES = [
    {id: LICENCE_STATUS_ACTIVE, name: (<Badge color="success" className="p-1 text-14"><FormattedMessage defaultMessage={"Aktivní"} /></Badge>)},
    {id: LICENCE_STATUS_CANCEL, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Zrušená"} /></Badge>)},
    {id: LICENCE_STATUS_EXPIRED, name: (<Badge color="secondary" className="p-1 text-14"><FormattedMessage defaultMessage={"Expirovaná"} /></Badge>)},
    {id: LICENCE_STATUS_WAIT, name: (<Badge color="black" className="p-1 text-14"><FormattedMessage defaultMessage={"Čeká"} /></Badge>)},
];